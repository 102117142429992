@font-face{
  src:url('/public/font/LeelawUI.ttf');
  font-family: LeelawUI
}
@font-face{
  src:url('/public/font/Segoe UI.ttf');
  font-family: Segeo
}
@font-face{
  src:url('/public/font/made/MADE Outer Sans Light PERSONAL USE.otf');
  font-family: Outer
}
*{
  padding:0;
  margin:0;
  box-sizing: border-box;
  zoom:1
}
*:focus {
    outline: none;
}
img{
  width:100%
}

.home__page__container{
  display:flex;
  justify-content: space-between;
  /* flex-wrap:wrap; */

  /* max-width:2100px; */
  margin:0 auto;
  /* align-items:center; */
  height:100%
}
.header{
  margin:68px 0 0 172px;
  display:flex;
  align-items:center;
}

.home__page__container{
  background-image:url('../../public/bg.png');
  background-repeat : no-repeat;
background-position : left -470px top -358px;

}
.sect--2{
  background-image:url('../../public/Groupe 1455.png');
  background-repeat : no-repeat;
background-position :left 75px top -100px

}
.header__img{
  margin-right:41px;
  margin-left:12px
}
.header__text{
  padding-left:41px;
  border-left: 4px solid #B900FE;
  height:70px;
}
.header__text p:first-child{
  color: #FE9C75;
  font-size:27px;
  /* white-space: nowrap; */
  font-family:Outer
}
.header__text p:last-child{
  color: #B900FE;
  font-size:25px;
     white-space: nowrap;
     font-family:Segeo
}
main,footer{

  margin-left:172px
}
main .title{
  font-size:99px;
  color:#B900FE;
  margin-top: 220px;
  margin-bottom:18px;
  line-height: 1;
  font-family : Outer;
  font-weight:300
}
form{
 max-width:675.63px;
  position:relative;
}
main input {
  background-color:rgba(254,152,111,50%);
  width:675.63px;
 padding: 28px 220px 27px 54px;
    border-radius: 12px;
  border:none;
  font-size:24px;
  font-family:LeelawUI
}
main button {
  background-color:#FE986F;
padding:32px 50px 28px 56px;
    border-radius: 12px;
  border:none;
  font-size:22px;
  color:#ffffff;
  font-weight:bold;
  
    top: 0;
    right: 0;

      /* margin-left: -198px; */
      position:absolute;
      cursor:pointer
}
main .prevent{
  color:#A7A7A7;
  font-size:24px;
  margin-top:24px;
  margin-bottom:245px;
   font-family:Segeo
}
.section__image {
    margin-right: 180px;
    padding-top: 85px;
}
.section__image img{
  width : 100%;

}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #C55A2F;
  opacity: 1; /* Firefox */
  font-size:24px;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #C55A2F;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #C55A2F;
}

footer{
  margin-bottom:40px;
  display:flex;
  align-items:center;
      margin-top: -140px;
      font-family:Segeo
}
footer .social__media {
   border-right: 2px solid #707070;
       display: flex;
       padding-right:49px;
}
footer .social__media a{
  margin: 0 10px
}
.contact__info {
   margin-left:49px;
   display:flex;
   align-items:center;
   white-space: nowrap;
}
.contact__info p{
  color:#6C6C6C;
  font-weight:Semibold;
  font-size:22px;
  margin-left:18px
}
.contact__info img{
  width:18px;
  height:18px;
      margin-top: 7px;

}
.contact__info .email{
  width:25px;
}
.contact__info div{
 display:flex
}
.contact__info div:last-child{
  margin-left:46px
}



/* @media only screen and (max-width: 1820px) {
  .header{
      margin:68px 0 0 24px;
}
main,footer{

  margin-left:24px;
  margin-right:24px;
}footer{
  flex-wrap:wrap
}
footer{
  margin-top:24px
}
} */

@media only screen and (max-width: 1590px) {
   *{
     overflow:visible;
   }
  html{
    zoom:0.7;
    
  }
}


@media only screen and (max-width: 1290px) {
 .home__page__container{
  flex-wrap:wrap;
  height:auto;
  margin:0 auto;
  width:100%;
  justify-content:center
}
.first__div{
  flex:1
}
 .header{
    margin: 68px 24px 0 24px;
        justify-content: center;
}
main .title {
    font-size: 77px;
    color: #B900FE;
    margin-top: 90px;
    margin-bottom: 18px;
    line-height: 1;
}
main input {
    background-color: rgba(254,152,111,50%);
    padding: 28px 220px 27px 54px;
    border-radius: 12px;
    border: none;
}
main .prevent {
    color: #A7A7A7;
    font-size: 24px;
    margin-top: 24px;
    margin-bottom: 42px;
}
footer{
  flex-wrap:wrap
}
footer{
  margin-top:24px
}
}
@media only screen and (max-width: 720px) {
 html{
    zoom:0.7
  }
   .section__image {
    margin-right: 20px
    ;}
  .section__image img{
   width:100%
  }
  .header__text p:last-child {
   white-space: inherit;
  
}
main input {
    background-color: rgba(254,152,111,50%);
    padding: 27px 220px 27px 54px;
    width:100%;
    /* margin-right:12px */
}
main button {
    right:0
}
footer {
  flex-wrap:wrap
}
footer .social__media {
  flex:1;
  justify-content:center;
  border:none
}
footer .contact__info {
  flex:1;
  justify-content:center;
  border:none;
  flex-wrap:wrap;
  margin-left:0
}
main .prevent {

    font-size: 20px;
   
}
}
@media only screen and (max-width: 615px) {
  .header{
      margin:68px 0 0 24px;
}
  .header{
    flex-wrap:wrap
  }
  main,footer{

  margin-left:24px;
  margin-right:24px;
}
  .header__text{
    padding-left:0;
    border:none;
    text-align:center
  }
  .social__media .contact__info{
    flex-wrap:wrap
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */

  font-size:20px;
}
main input {
    background-color: rgba(254,152,111,50%);
    padding: 20px 220px 24px 34px;

}
main button {
    padding: 25px 50px 24px 56px;
}
form p {
  font-size:20px !important
}
footer .social__media {padding-right:0}
  
}